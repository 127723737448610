import React, { useState } from 'react'
import { useRouter } from 'next/router'
import { FormattedMessage, useIntl } from 'react-intl'
import { NextPage } from 'next'
import Link from 'next/link'
import styled from 'styled-components'
import { FormikHelpers } from 'formik'
import { PageContainer } from '../components/layout'
import { PageTitle } from '../components/common'
import routes from '../core/application/routes'
import { useAppProviderContext } from '../core/application/contexts'
import { IFormValues, LoginForm } from '../components/pages/login'
import { isAuthProviderError } from '../core/domain/providers'


const LoginPage: NextPage = () => {
  const router = useRouter()
  const intl = useIntl()
  const [formError, setFormError] = useState<string | undefined>(undefined)
  const { authProvider, swappyProvider } = useAppProviderContext()

  const initialValues: IFormValues = {
    email: '',
    password: ''
  }

  const handleSubmit = async ({ email, password }: IFormValues, {
    setFieldError,
    setSubmitting
  }: FormikHelpers<IFormValues>) => {
    setSubmitting(true)
    setFormError(undefined)
    if (authProvider && swappyProvider) {
      try {
        await authProvider.login(email, password)
        await router.replace(routes.ACCOUNT)
      } catch (error) {
        console.error(error)
        const intlKey = isAuthProviderError(error) ? `Common.Errors.${error.code}` : 'Common.Errors.UNKNOWN_ERROR'
        setFieldError('password', intl.formatMessage({ id: intlKey }))
      } finally {
        setSubmitting(false)
      }
    }
  }

  return (
    <PageContainer>
      <PageTitle>
        <FormattedMessage id='LoginPage.PageTitle' />
      </PageTitle>
      <FormContainer>
        <LoginForm initialValues={initialValues} formError={formError} onSubmit={handleSubmit} />
        <BottomFormSection>
          <BottomLink>
            <Link href={routes.RESET_PASSWORD}>
              <a>
                <FormattedMessage id='LoginPage.BottomFormSection.ForgotPassword' />
              </a>
            </Link>
          </BottomLink>
        </BottomFormSection>
        <BottomSection>
          <BottomLink>
            <FormattedMessage id='LoginPage.BottomSection.Register.Part1' />&nbsp;
            <Link href={routes.REGISTRATION}>
              <a><FormattedMessage id='LoginPage.BottomSection.Register.Part2' /></a>
            </Link>
          </BottomLink>
        </BottomSection>
      </FormContainer>
    </PageContainer>
  )
}

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: 0.4rem;
  border: solid 1px ${({ theme }) => theme.color.lightGrey};
  max-width: 40rem;
`

const BottomFormSection = styled.div`
  padding: 0 4.9rem 4.9rem 4.9rem;
  margin-top: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

const BottomLink = styled.div`
  color: ${({ theme }) => theme.color.anotherGrey};
  font-size: ${({ theme }) => theme.size.smaller};
  font-style: italic;

  a {
    cursor: pointer;
    text-decoration: none;
    color: ${({ theme }) => theme.color.primary};
  }
`

const BottomSection = styled.div`
  padding: 2.7rem 4.9rem;
  border-top: solid 1px ${({ theme }) => theme.color.lightGrey};
`

export default LoginPage
