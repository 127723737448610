import React from 'react'
import styled from 'styled-components'

export interface IRoundedTagProps {
  customClass?: string
  onClick?: () => void
}

const RoundedTag: React.FC<IRoundedTagProps> = ({ onClick, customClass, children }) => {
  return (
    <Container onClick={onClick} className={customClass}>
      {children}
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0.7rem;
  border-radius: 2px;
  cursor: pointer;

  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.smaller};
  color: ${({ theme }) => theme.color.white};
  background-color: ${({ theme }) => theme.color.primary};

  &.ps2,
  &.ps3,
  &.ps4,
  &.ps5 {
    background-color: blue;
  }

  &.xbox,
  &.xbox_360,
  &.xbox_one,
  &.xbox_series {
    background-color: green;
  }

  &.wii,
  &.wii_u {
    background-color: lightskyblue;
  }

  &.gamecube {
    background-color: blueviolet;
  }

  &.nintendo_ds,
  &.nintendo_3ds,
  &.switch {
    background-color: red;
  }
`

export default RoundedTag
