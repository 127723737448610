import styled from 'styled-components'

export interface IPageSubtitleProps {
  hidden?: boolean
}

export default styled.h2<IPageSubtitleProps>`
  font-weight: ${({ theme }) => theme.weight.normal};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.grey};
  visibility: ${({ hidden }) => hidden ? 'hidden' : 'visible'};
`
