import React from 'react'
import { Form, Formik, FormikHelpers } from 'formik'
import { FormattedMessage, useIntl } from 'react-intl'
import * as yup from 'yup'
import styled from 'styled-components'
import { breakpoints } from '../../../theme/breakpoints'
import { ErrorMessageWrapper, TextInput } from '../../common/inputs'
import { FullSizeSubmitButton } from '../../common/buttons'

export interface IFormValues {
  email: string
  password: string
}

export interface ILoginFormProps {
  initialValues: IFormValues
  formError?: string
  onSubmit: (values: IFormValues, helpers: FormikHelpers<IFormValues>) => void
}

const LoginForm: React.FC<ILoginFormProps> = ({ initialValues, formError, onSubmit }) => {
  const intl = useIntl()

  const validationSchema = yup.object({
    email: yup
      .string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'Common.Errors.FieldRequired'
        })
      )
      .email(
        intl.formatMessage({
          id: 'Common.Errors.EmailNotValid'
        })
      ),
    password: yup
      .string()
      .trim()
      .required(
        intl.formatMessage({
          id: 'Common.Errors.FieldRequired'
        })
      )
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      validateOnBlur={false}
      validateOnChange
      onSubmit={onSubmit}
    >
      {({ errors, isSubmitting, submitCount }) => {
        return (
          <CustomForm noValidate>
            <TextInput
              id='email'
              label={intl.formatMessage({
                id: 'Common.Form.EmailLabel'
              })}
              placeholder='r.gosling@mail.fr'
              type='email' autoComplete='email'
              error={(submitCount && errors.email) || null}
            />
            <TextInput
              id='password'
              label={intl.formatMessage({
                id: 'Common.Form.PasswordLabel'
              })}
              type='password'
              autoComplete='current-password'
              error={(submitCount && errors.password) || null}
            />
            {formError && <ErrorMessageWrapper isVisible>{formError}</ErrorMessageWrapper>}
            <FullSizeSubmitButton type='submit' disabled={isSubmitting}>
              <FormattedMessage id='LoginPage.Form.SubmitButtonLabel' />
            </FullSizeSubmitButton>
          </CustomForm>
        )
      }}
    </Formik>
  )
}

const CustomForm = styled(Form)`
  padding: 3rem 2rem;

  @media ${breakpoints.tablet} {
    padding: 4.9rem 4.9rem 0 4.9rem;
  }

  & > div:not(:last-of-type) {
    margin-bottom: 1.6rem;
  }

  & > :last-child {
    margin-top: 2.7rem;
  }
`

export default LoginForm
