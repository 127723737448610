import React from 'react'
import styled from 'styled-components'

export interface IGameResultItemProps {
  id: string
  name: string
  cover?: string
  platforms: Array<string>
  onClick?: (id: string) => void
}

const GameResultItem: React.FC<IGameResultItemProps> = ({ id, name, cover, platforms, onClick }) => {
  return (
    <Container onClick={onClick ? () => onClick(id) : undefined}>
      {cover &&
        <CoverWrapper>
          <img src={cover} alt={name} decoding='async' />
        </CoverWrapper>}
      <LeftWrapper>
        <NameWrapper>
          {name}
        </NameWrapper>
      </LeftWrapper>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  row-gap: 1rem;
  border-radius: 0.4rem;
  border: 1px solid ${({ theme }) => theme.color.lightGrey};
  padding: 1rem 1rem;
  cursor: pointer;
`

const LeftWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`

const NameWrapper = styled.div`
  text-align: center;
  font-weight: ${({ theme }) => theme.weight.bold};
  font-size: ${({ theme }) => theme.size.normal};
  color: ${({ theme }) => theme.color.black};
`

const GamePlatformGrid = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  max-width: 15rem;
  column-gap: 0.3rem;
  row-gap: 0.5rem;
`

const GamePlatformTag = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.6rem 0.7rem;
  border-radius: 2px;
  cursor: pointer;

  font-weight: ${({ theme }) => theme.weight.semi};
  font-size: ${({ theme }) => theme.size.smaller};
  color: ${({ theme }) => theme.color.white};

  &.ps2,
  &.ps3,
  &.ps4,
  &.ps5 {
    background-color: blue;
  }

  &.xbox,
  &.xbox_360,
  &.xbox_one,
  &.xbox_series {
    background-color: green;
  }

  &.wii,
  &.wii_u {
    background-color: lightskyblue;
  }

  &.gamecube {
    background-color: blueviolet;
  }

  &.nintendo_ds,
  &.nintendo_3ds,
  &.switch {
    background-color: red;
  }
`


const CoverWrapper = styled.div`
  height: 10rem;

  img {
    height: 100%;
    width: auto;
  }

`


export default GameResultItem
